import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import builderConfig from "../../config/builder-config.json"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>{builderConfig.app.name}</h1>
    <h2>
      {builderConfig.slug}-{builderConfig.version}
    </h2>
  </Layout>
)

export default IndexPage
